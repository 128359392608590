import React, { useState, useEffect } from "react";
import PublicCatalogLayout from "@/layouts/PublicCatalogLayout"
import { fetchAPI } from "@/utility/NetworkUtils";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import DataGridTableToolbar from "@/utility/DataGridTableToolbar";
import { Link, Button } from "@mui/material";
import ItemForm from "../../items/form/ItemForm";
const InventoryItemsDashboard = ({
  renter,
  item,
  user
}) => {

  const [ categories, setCategories ] = useState([]);

  useEffect(() => {
    fetchCategories()
  }, [])

  fetchCategories = async () => { 
    fetchAPI(`/webapi/categories`, (json)=> {
      if (!json.errors) {
        setCategories(json.categories)
      } else {
        console.log({
          errors: json.errors
        })
      }
    });
  } 

  return (
    <PublicCatalogLayout
      classNames={{container: 'full-body-container inventory-dashboard'}}
      className="inventory-dashboard"
      renter={renter}
      currentPage={"inventory-"+renter.id}
      user={user}
    >
      <ItemForm
        item={item}
        renter={renter}
        user={user}
        items={[]}
        renters={[]}
        categories={categories}
        onSuccessfulSaveUrl={
          `/inventory/${renter.id}/items`
        }

        disableLabels={true}
        disableVariants={true}
        disableInventoryLog={true}
        disablePriceToRent={true}
      />
    </PublicCatalogLayout>
  );
}

export default InventoryItemsDashboard