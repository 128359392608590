import React from "react";
import PropTypes from "prop-types";
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import EventSubheader from "../events/partials/EventSubheader"
import KitShowItemCard from "./components/KitShowItemCard";
import KitHoldForm from "../events/holds/KitHoldForm";

class KitShowPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  getEscapeUrl(){
    const { item, event, category, user } = this.props;
    if(event){
      return `/events/${event.slug}`
    }
    return document.referrer
  }

  goBack(){
    window.location.href = this.getEscapeUrl();
  }

  renderEscapeButtons() {
    const { event, category } = this.props;
    return (
      <div className="row escape-buttons">
        <div className="col-xs-6">
          <a href={this.getEscapeUrl()} >
            {`< Back`}
          </a>
        </div>
        <div className="col-xs-6 text-right">
          <a class="x" href={this.getEscapeUrl()}>
            x
          </a>
        </div>
      </div>
    )
  }

  render() {
    const { kit, event } = this.props;
    const items = kit.items.map(i => {let item = i.item; item.count = i.item_count; return item})
    return (
      <PublicCatalogLayout
        {...this.props}
        groups={[]}
        subHeader={
          event ?
          (
            <EventSubheader {...this.props}/>
          )
          :
          null
        }
        currentPage="kits"
        classNames={{body: 'white-background'}}
      >
        <div className="item-detail-show">
          {this.renderEscapeButtons()}
          <div className="kit-show-header row"><h1>{kit.name}</h1></div>
          <div className="row">
            <div className="kit-show-item-list col-xs-12 col-sm-5">
              {items.length > 0 ? items.map(item => <KitShowItemCard item={item}/>): <div>There are no items in this kit yet</div>}
            </div>
            <div className="col-xs-12 col-sm-7">
              <div className="row">
                <div className="col-xs-12">
                  <div className="kit-show-image" style={{backgroundImage: `url(${kit.images[0] ? kit.images[0].image : ""})`}}>
                  </div>
                  <KitHoldForm {...this.props}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PublicCatalogLayout>
    );
  }
}

export default KitShowPage;
