import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import moment from 'moment';
import Cookies from "universal-cookie";
class InventoryLogsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item_inventory_logs: props.item_inventory_logs
    }

    this.deleteTransaction = this.deleteTransaction.bind(this);
  }

  renderItemLogTable() {
    const { item_inventory_logs } = this.state;
    const rows = item_inventory_logs.sort((a,b) => {
      const aDate = new Date(a.created_at);
      const bDate = new Date(b.created_at);
      return bDate - aDate;
    }).map(log=>(
      <tr>
        <td>{moment(new Date(log.created_at)).format(" MMMM Do YYYY, h:mm:ss a")}</td>
        <td>{log.count}</td>
        <td>{log.theoretical_total}</td>
        <td>{log.reason}</td>
        <td className="options"><button className="btn btn-danger" onClick={() => this.deleteTransaction(log)}> Delete </button></td>
      </tr>
    ));
    return (
      <table className="warehouse-basic-table">
        <thead>
          <tr>
            <th>Created At</th>
            <th>Count</th>
            <th>Total at Time</th>
            <th>Transaction Type</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    )
  }

  deleteTransaction(log) {
    if(confirm("Are you sure you want to delete this? This is NOT recommended unless you truly made a mistake. Doing this may make the inventory count less than zero.")) {
      
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/warehouse/items/${this.props.item.id}/inventory/${log.id}`, {
          method: 'DELETE',
          redirect: "manual",
          headers: {
            "X-CSRF-Token": token,
          }
        })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            this.setState({
              item_inventory_logs: this.state.item_inventory_logs.filter(log2 => log.id != log2.id)
            });
          } else {
            alert(json.error);
          }
        }); 
    }
  }

  render() {
    return (
      <WarehouseLayout currentPage="dashboard">
        <a href={`/warehouse/items/${this.props.item.id}/edit`}> Back to Edit </a>
        <div className="warehouse-card">
          <h3> Inventory Log: {this.props.item.name}</h3>

          <div className="row">
            <div className="col-xs-12">
              {this.renderItemLogTable()}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

InventoryLogsDashboard.propTypes = {
  item: PropTypes.object.isRequired,
  item_inventory_logs: PropTypes.array.isRequired,
};

export default InventoryLogsDashboard;
