import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class WarehouseGroupEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    const { category } = this.props;
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green pull-right">Edit Group</button>);
  }

  renderSaveButton(formikProps) {
    return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm} disabled={!formikProps.dirty}> Save </button>
  }

  formMethod() {
    return 'PATCH'
  }

  formURL() {
    return  `/warehouse/categories/update_group/`
  }

  updateGroup(selectedValue) {
    this.setState({
      group: selectedValue
    })
  }

  renderForm(){
    const { group } = this.props;
    return (
      <Formik
        initialValues={{
          original_group_name: group || "",
          group: group || "",
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.closeModal();
                this.props.updateGroup(json.categories);
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-attachment-form">
            <div className="row">
              <div className="col-xs-12">
                <label> Group Name</label>
                <Field className="form-control" name="group" />
              </div>
            </div>
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Attachment"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default WarehouseGroupEditModal