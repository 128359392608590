import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class WarehouseCategoriesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      group: props.category ? {value: props.category.group_name, label: props.category.group_name} : null
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getGroups = this.getGroups.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    const { category } = this.props;
    this.setState({
      modalOpen: true,
      group: category ? {value: category.group_name, label: category.group_name} : null
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { category } = this.props;
    if(category){
      return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green pull-right">Edit</button>);
    }
    return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green">Add Category</button>);
  }

  renderSaveButton(formikProps) {
    return <button className="btn btn-primary pull-right" type="submit" onClick={formikProps.submitForm}> Save </button>
  }

  formMethod() {
    const { category } = this.props;
    if( category ){
      return 'PATCH'
    }
    return 'POST'
  }

  formURL() {
    const { category } = this.props;
    if( category ){
      return  `/warehouse/categories/${category.id}`
    }
    return `/warehouse/categories/`
  }

  getGroups() {
    const groups = this.props.groups.map(g => {
      if (g == null) {
        return {value: null, label: "Misc"}
      } else {
        return {value: g,label: g}
      }
    });
    return groups;
  }

  updateGroup(selectedValue) {
    this.setState({
      group: selectedValue
    })
  }

  renderForm(){
    const { category } = this.props;
    return (
      <Formik
        initialValues={{
          category: {
            name: category ? category.name : "",
            group_name: category ? category.group_name : ""
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          values['category']['group_name'] = this.state.group ? this.state.group.value : null;
          console.log(values);
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.closeModal();
                if(category) {
                  this.props.updateCategory(json.category);
                } else {
                  this.props.addCategory(json.category);
                }
               
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-attachment-form">
            <div className="row">
              <div className="col-xs-12">
                <label> Category </label>
                <CreatableSelect
                  value={this.state.group} 
                  onChange={this.updateGroup}
                  options={this.getGroups()}
                  name="category[group_name]"
                />
                <label> Sub Category </label>
                <Field className="form-control" name="category[name]" />
     
              </div>
            </div>
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Attachment"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default WarehouseCategoriesModal