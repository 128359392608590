import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import ItemCard from "../items/components/ItemCard"
import { isJaguar, isSuperadmin } from "../utility/UserUtils"
import KitCard from "../kits/KitCard";
import InventoryCategorySubheader from "./InventoryCategorySubheader";

class InventoryCategoryDashboard extends React.Component {

  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
    }
  }

  renderItems() {
    let { items, user } = this.props;
    return this.getSortedItems().sort((a,b) => (a.name < b.name ? -1 : 1)).filter(item=> !item.variant_of_id).map(item => (
      <ItemCard inventory={true} item={item} user={user}/>
    ))
  }

  renderKits() {
    let { user } = this.props;
    return this.getSortedKits().map(kit => (
      <KitCard inventory={true} kit={kit} user={user}/>
    ))
  }

  getSortedItems(){
    let { items } = this.props;
    return items;
  }

  getSortedKits() {
    let { kits } = this.props;
    return kits.sort((a, b) => 
      (a.name < b.name ? -1 : 1)
    )
  }

  render() {
    const { renter, group } = this.props;

    return (
      <PublicCatalogLayout
        classNames={{container: 'full-body-container inventory-dashboard'}}
        className="inventory-dashboard"
        currentGroup={
          group
        }
        subHeader={<InventoryCategorySubheader {...this.props}/>}
        currentPage={"inventory-"+renter.id}
        {...this.props}
      >
        {this.renderKits()}
        {this.renderItems()}
      </PublicCatalogLayout>
    );
  }
}

InventoryCategoryDashboard.propTypes = {

};

export default InventoryCategoryDashboard;
