import React from "react";
import PropTypes, { number } from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import Select from "react-select"
import moment from "moment"
import Cookies from "universal-cookie";
import ShippingAddressModal from "../../events/shipping_addresses/ShippingAddressModal"
import { holdKey } from "../../utility/HoldUtils"
import WarehouseEventsHoldReturnedModal from "./partials/WarehouseEventsHoldReturnedModal"
import ItemInventorysModal from '../../items/form/ItemInventoryModal'

class WarehouseEventsShowPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: props.event,
      item_holds: props.item_holds,
      items: props.items,
      sortBy: { label: "Item Name", value: "name" },
      filterBy: null,
      shipping_addresses: props.shipping_addresses,
      selectedHolds: [],
      returnModalHold: null,
      confirmationMessage: null,
    }

    this.possibleStatuses = holdKey.map(type => {
      return {
        label: type,
        value: type,
      }
    })
    this.updateItem = this.updateItem.bind(this);
    this.timeOutObjects = {}
    this.updateHoldStatus = this.updateHoldStatus.bind(this);
    this.updateSortBy = this.updateSortBy.bind(this);
    this.updateShippingAddress = this.updateShippingAddress.bind(this);
    this.updateHoldLocked = this.updateHoldLocked.bind(this);
    this.updateHold = this.updateHold.bind(this);
    this.updateHoldCount = this.updateHoldCount.bind(this);
    this.updateFilterBy = this.updateFilterBy.bind(this);
    this.updateHoldState = this.updateHoldState.bind(this);
  }

  getItem(hold) {
    const { items } = this.state;
    return items.filter(item => item.id == (hold.item.variant_of_id || hold.item_id))[0] || {}
  }

  getActualItem(hold) {
    const { items } = this.state;
    return items.filter(item => item.id == (hold.item_id))[0] || {}
  }

  getSortedItemHolds(item_holds) {
    const { sortBy } = this.state;
    item_holds = item_holds.sort((a, b) => a.id - b.id);
    switch (sortBy.value) {
      case "created_at":
        return item_holds.sort((a, b) => moment(b.created_at) - moment(a.created_at))
      case "name":
        return item_holds.sort((a, b) => ((this.getItem(a) || {}).name || "").toLowerCase() < ((this.getItem(b) || {}).name || "").toLowerCase() ? -1 : 1)
      case "updated_at":
        return item_holds.sort((a, b) => moment(b.updated_at) - moment(a.updated_at))
      case "status":
        return item_holds.sort((a, b) => {
          const aSort = holdKey.findIndex(x => x == a.status);
          const bSort = holdKey.findIndex(x => x == b.status);
          return aSort - bSort;
        })
      case "total_count":
        return item_holds.sort((a, b) => b.total_count - a.total_count)
      case "shipping_address_id":
        return item_holds.sort((a, b) => {
          const sortA = a.shipping_address ? a.shipping_address.venue_name.toLowerCase() : "zzzzzzzzzzz"
          const sortB = b.shipping_address ? b.shipping_address.venue_name.toLowerCase() : "zzzzzzzzzzz"
          return sortA < sortB ? -1 : 1;
        })
      default:
        return item_holds.sort((a, b) => a.id - b.id);
    }
  }

  getFilteredItemHolds(item_holds) {
    const { filterBy } = this.state;
    if (filterBy) {
      if (filterBy.value.includes('status-')) {
        const filterStatus = filterBy.value.split("-")[1]
        console.log({ filterBy, filterStatus })
        return item_holds.filter(hold => hold.status == filterStatus)
      }
      else {
        switch (filterBy.value) {
          case "all":
            return item_holds;
          default:
            return item_holds;
        }
      }
    }
    return item_holds;
  }

  updateShippingAddress(address) {
    this.setState({
      shipping_addresses: this.state.shipping_addresses.filter(address2 => address2.id != address.id).concat(address)
    })
  }


  renderHoldTableRow(hold, selected) {
    const {selectedHolds} = this.state;
    const rootItem = this.getItem(hold);
    const actualItem = this.getActualItem(hold);
    const holdIsSelected = selectedHolds.findIndex(selectedHold => selectedHold.id == hold.id) >= 0
    return (
      <React.Fragment>
        <tr>
          <td>
            <input type="checkbox" checked={holdIsSelected} onClick={() => this.updateSelectedHolds(hold)} />
          </td>
          <td><a
            className="gold-link bold-link"
            href={`/warehouse/items/${rootItem.id}/edit`}
            style={hold.item.deleted_at ? { color: 'red', textDecoration: 'line-through' } : {}}
          >{rootItem.name} {hold.item.variant_of_id ? `- ${hold.item.name}` : ""}
          </a></td>
          <td>
            {hold.shipping_address_id ?
              <ShippingAddressModal updateShippingAddress={this.updateShippingAddress} button={(<a href="#" className="white-link">{hold.shipping_address ? hold.shipping_address.venue_name : "Unassigned"}</a>)} address={this.state.shipping_addresses.filter(x => x.id == hold.shipping_address_id)[0] || {}} />
              : "Unassigned"}
          </td>
          <td>
            <input onChange={(e) => this.updateHoldCount(hold, e)} className="form-control" type="number" step="1" value={hold.total_count} disabled={hold.locked} />
          </td>
          <td className="text-center">
            <img className="warehouse-event-show-item-hold-lock" onClick={(e) => this.updateHoldLocked(hold)} src={`/images/items/warehouse-${hold.locked ? "" : `un`}locked.png`} />
          </td>
          <td>
            {this.renderHoldStatus(hold)}
          </td>
          <td style={{textAlign: "center"}}>
            {actualItem.owned}
          </td>
          <td>
            <ItemInventorysModal i updateItem={this.updateItem} item={hold.item} updateInventoryCount={()=>{}}>
              <button style={{whiteSpace: "pre-wrap"}} className="btn btn-primary"> Quick Add Inventory </button>
            </ItemInventorysModal>
          </td>
          <td className="notes-cell" >
            <textarea style={{ minWidth: "100%", width: "100%" }} onChange={(e) => this.updateHoldNotes(hold, e.target.value || "")} value={hold.notes} className="form-control"/>
          </td>

        </tr>
      </React.Fragment>
    )
  }

  updateItem(item) {
    this.setState((state, props) => {
      state.items = state.items.filter(item2 => item2.id != item.id).concat([item])
      return state;
    });
  }

  renderHoldStatus(hold) {
    return (
      <Select
        value={this.possibleStatuses.find(x => x.value == hold.status)}
        options={this.possibleStatuses}
        styles={{ menu: (provided, state) => { provided['color'] = 'black'; return provided } }}
        onChange={(e) => this.updateHoldStatus(hold, e)}
        isDisabled={hold.status == "received"}
      />
    )
  }

  renderHoldsTable() {
    const { item_holds } = this.state;
    const rows = this.getFilteredItemHolds(this.getSortedItemHolds(item_holds)).map((hold) => this.renderHoldTableRow(hold, false));
    return (
      <table className="warehouse-basic-table warehouse-events-show-table">
        <thead>
          <tr>
            <th style={{ width: "10px" }}></th>
            <th class="name-header">Item Name</th>
            <th style={{ paddingLeft: "10px", width: "120px" }}>Location </th>
            <th style={{ width: "60px" }}>Qty.</th>
            <th className="text-center" style={{ width: "75px", paddingLeft: "0px", paddingRight: "0px" }}> Locked? </th>
            <th className="" style={{ paddingLeft: "10px", width: "150px" }}>Status</th>
            <th style={{maxWidth:"80px", width:"80px"}}>Owned</th>
            <th style={{maxWidth:"70px", width:"70px"}}></th>
            <th className="notes-header">Notes</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  getSortByOptions() {
    return [
      { label: "Item Name", value: "name" },
      { label: "Last Updated", value: "updated_at" },
      { label: "Created At", value: "created_at" },
      { label: "Status", value: "status" },
      { label: "Total #", value: "total_count" },
      { label: "Location", value: "shipping_address_id" }
    ]
  }

  getFilterByOptions() {
    return [
      { label: "All", value: "all" },
      { label: "Status: Reserved", value: "status-reserved" },
      { label: "Status: Packed", value: "status-packed" },
      { label: "Status: Shipped", value: "status-shipped" },
      { label: "Status: Received", value: "status-received" },
    ]
  }

  updateHoldStatus(hold, selectedValue, numberReturned = null) {
    const { event } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if (selectedValue.value == "received") {
      if (numberReturned == null) {
        this.setState({returnModalHold: hold})
      }
    }
    const response = {
      item_hold: {
        status: selectedValue.value,
      }
    }
    if (selectedValue.value != 'reserved') {
      response['item_hold']['locked'] = true
    }
    fetch(`/events/${event.slug}/item_holds/${hold.id}`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify(response),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState((state, props) => {
            state.item_holds = state.item_holds.filter(hold2 => hold2.id != json.item_hold.id).concat([json.item_hold])
            state.items = state.items.filter(item2 => item2.id != json.item.id).concat([json.item])
            return state;
          });
        } else {
          alert(json.error);
        }
      });
  }

  updateHoldNotes(hold, new_notes) {
    const newHold = hold;
    newHold.notes = new_notes;
    const { item_holds } = this.state;
    this.setState({
      item_holds: item_holds.filter(hold2 => hold2.id != hold.id).concat([newHold])
    })
    const timeoutId = `hold-note-timeout-id-${hold.id}`
    if (this.timeOutObjects[timeoutId]) {
      clearTimeout(this.timeOutObjects[timeoutId]);
    }

    this.timeOutObjects[timeoutId] = setTimeout(() => {
      const { event } = this.props;
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      const response = {
        item_hold: {
          notes: new_notes
        }
      }
      fetch(`/events/${event.slug}/item_holds/${hold.id}`, {
        method: 'PATCH',
        redirect: "manual",
        body: JSON.stringify(response),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) { } else { alert(json.error); }
        });
    }, 1000);

  }

  updateHoldCount(hold, e) {
    const newHold = hold;
    newHold.total_count = e.target.value
    const { item_holds } = this.state;
    this.setState({
      item_holds: item_holds.filter(hold2 => hold2.id != hold.id).concat([newHold])
    })
    if (e.target.value && e.target.value > 0) {
      const { event } = this.props;
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      const response = {
        item_hold: {
          total_count: e.target.value,
          status: 0,
        }
      }
      fetch(`/events/${event.slug}/item_holds/${hold.id}`, {
        method: 'PATCH',
        redirect: "manual",
        body: JSON.stringify(response),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            this.setState((state, props) => {
              return state;
            });
          } else {
            alert(json.error);
          }
        });
    }
  }

  updateHoldLocked(hold, value) {
    const body = {
      item_hold: {
        locked: value ? (value == "lock") : !hold.locked,
      }
    }
    this.updateHold(body, hold);
  }

  checkSanity(hold) {
    if (hold.has_error &&
      !confirm("This hold appears to have an error, like missing an address. Are you sure you want to do this?")
    ) {
      return true;
    }
    return false
  }

  updateHold(body, hold) {
    const { event } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/events/${event.slug}/item_holds/${hold.id}`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify(body),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState((state, props) => {
            state.item_holds = state.item_holds.filter(hold2 => hold2.id != json.item_hold.id).concat([json.item_hold])
            return state;
          });
        } else {
          alert(json.error);
        }
      });
  }

  updateHoldState(item_hold) {
    this.setState((state, props) => {
      state.item_holds = state.item_holds.filter(hold2 => hold2.id != item_hold.id).concat([item_hold])
      return state;
    });
  }

  updateSortBy(selectedValue) {
    this.setState({
      sortBy: selectedValue
    })
  }

  updateFilterBy(selectedValue) {
    this.setState({
      filterBy: selectedValue
    })
  }

  updateSelectedHolds(hold) {
    let { selectedHolds } = this.state
    const index = selectedHolds.findIndex((selectedHold) => selectedHold.id == hold.id)
    index < 0 ? selectedHolds.push(hold) : selectedHolds.splice(index, 1)
    this.setState({
      selectedHolds: selectedHolds,
    })
  }

  triggerConfirmationMessage(message) {
    console.log(message)
    this.setState({confirmationMessage: message})
    setTimeout(() => {
      this.setState({confirmationMessage: null})
    }, 5000)
  }

  renderConfirmationMessage() {
    const {confirmationMessage} = this.state;
    if (confirmationMessage) {
      return (
        <div className='warehouse-confirmation-message-container'>
          <div className='warehouse-confirmation-message'>
            {confirmationMessage}
          </div>
        </div>
      )
    }
  }

  renderBatchActions() {
    const { selectedHolds } = this.state;
    const statusOptions = [
      //returned is excluded for now because due to needed notation of returned items
      {label: "reserved", value: "reserved"},
      {label: "packed", value: "packed"},
      {label: "shipped", value: "shipped"}
    ];
    return (
      <div className="row">
        <div className="col-xs-2" style={{ display: "flex" }}>
          <button onClick={() => selectedHolds.forEach((hold) => this.updateHoldLocked(hold, "lock"))} className="btn-danger form-control">
            Lock
          </button>
          &nbsp;
          <button onClick={() => selectedHolds.forEach((hold) => this.updateHoldLocked(hold, "unlock"))} className="btn-green form-control">
            Unlock
          </button>
        </div>
        <div className="col-xs-3">
          <label style={{ margin: '0' }}> Set status </label>
          <Select
            classNamePrefix={"custom-warehouse-event-select"}
            value={null}
            className="custom-warehouse-event-select"
            onChange={(e) => selectedHolds.forEach(hold => this.updateHoldStatus(hold, e))}
            options={statusOptions}
            styles={{ menu: (provided, state) => { provided['color'] = 'black'; return provided } }}
          />
        </div>
        <div className="col-xs-5"/>
        <div className="col-xs-2" style={{display: "flex", justifyContent: "flex-end"}}>
          <button onClick={() => this.setState({selectedHolds: []})} className="btn-grey form-control">
            Deselect All
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { event, sortBy, filterBy } = this.props;
    const { selectedHolds, returnModalHold } = this.state;
    const holdsAreSelected = this.state.selectedHolds.length > 0;
    return (
      <>
        <WarehouseLayout currentPage="dashboard">
          <div className="warehouse-card lighter-card">
            <div className="row">
              <div className="col-xs-12">
                <h1> {event.name} </h1>
                <a href={`/events/${event.slug}`}> See Front End </a>
              </div>
            </div>
          </div>
          <div className="warehouse-card">
            <div className="row">
              <div className="col-xs-4">
                <h1 style={{ margin: "0" }}> Holds </h1>
              </div>
              <div className="col-xs-8" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
                <label style={{ margin: '0' }}> Sort by </label>
                <Select
                  classNamePrefix={"custom-warehouse-event-select"}
                  value={sortBy}
                  className="custom-warehouse-event-select"
                  onChange={this.updateSortBy}
                  options={this.getSortByOptions()}
                  styles={{ menu: (provided, state) => { provided['color'] = 'black'; return provided } }}
                  />
                <label style={{ margin: '0' }}> Filter by </label>
                <Select
                  classNamePrefix={"custom-warehouse-event-select"}
                  value={filterBy}
                  className="custom-warehouse-event-select"
                  onChange={this.updateFilterBy}
                  options={this.getFilterByOptions()}
                  styles={{ menu: (provided, state) => { provided['color'] = 'black'; return provided } }}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12" style={{ paddingTop: "12px" }}>
                {holdsAreSelected && this.renderBatchActions()}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-xs-12 warehouse-event-show-card warehouse-events-table-card">
                {this.renderHoldsTable()}
              </div>
            </div>
            {returnModalHold != null && <WarehouseEventsHoldReturnedModal updateItem={this.updateItem.bind(this)} modalOpen={true} hold={returnModalHold} closeModal={() => this.setState({returnModalHold: null})} showAccessButton={false} updateHold={this.updateHold} triggerConfirmationMessage={this.triggerConfirmationMessage.bind(this)}/>}
          </div>
        </WarehouseLayout>
        {this.renderConfirmationMessage()}
      </>
    );
  }
}

WarehouseEventsShowPage.propTypes = {
  event: PropTypes.object.isRequired,
  item_holds: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};

export default WarehouseEventsShowPage;
