import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import CategorySubheader from "../categories/CategorySubheader"
import ItemImageComponent from "./components/ItemImageComponent"
import { isJaguar, isSuperadmin, isClient, canEditEvent } from "../utility/UserUtils"
import ReactTags from 'react-tag-autocomplete'
import Cookies from "universal-cookie";
import ItemHoldForm from "../events/holds/ItemHoldForm"
import EventSubheader from "../events/partials/EventSubheader"
import HoldMessageModal from "../events/holds/HoldMessageModal"
import moment from 'moment'
import ItemMessageComponent from "./components/ItemMessageComponent"
class ItemShowPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      itemTags: props.item.item_tags.map(tag => {return {id: tag.id, name: tag.tag}}),
      commonTags: [],
      locked: props.item_hold ? props.item_hold.locked : false
    }

    this.imageComponentReference = React.createRef();

    this.updateTags = this.updateTags.bind(this);
    this.handleTagDelete = this.handleTagDelete.bind(this);
    this.handleTagAddition = this.handleTagAddition.bind(this);
    this.loadTags = this.loadTags.bind(this);
    this.lockHold = this.lockHold.bind(this);
    this.goBack = this.goBack.bind(this);
    this.unlockHold = this.unlockHold.bind(this);
  }

  componentDidMount(){
    this.loadTags();
  }

  renderLinks(){
    const { user, item } = this.props;
    if(isJaguar(user)){
      return (
        <React.Fragment>
          <b>Links</b>
          {
            ( item.purchase_link && isJaguar(user) ) ? (
            <div className="item-link">
              <a
                target="_blank"
                href={item.purchase_link}
              >
                {item.purchase_link}
              </a>
            </div>
            ) : ""
          }
        </React.Fragment>
      )
    }
  }

  renderItemDetails() {
    const { item, user } = this.props;
    return (
      <div className="row item-information-container">
        <div className="col-xs-6">
        <b>Product Information</b>
        {
          item.rental_cost ?
          <div className="quickview-field rental-price">
            Rental Price: <b> ${parseFloat(item.rental_cost).toFixed(2)} </b>
          </div>
          : ""
        }
        {this.renderDimensions()}
        {this.renderCustomFields()}
        </div>
        <div className="col-xs-6">
          {this.renderLinks()}
          {this.renderAttachments()}
        </div>
      </div>
    )
  }

  renderAttachments(){
    const { user, item } = this.props;
    if(isJaguar(user)) {
      const attachments = item.item_attachments.map(attachment =>
        <div className="item-attachment">
          <a href={attachment.attachment} target="_blank">
            {attachment.filename}
          </a>
        </div>
      )
      return (
        <div className="full-view-item-attachments">
          <br />
          <b>Attachments</b>
          { attachments }
        </div>
      )
    }
  }

  renderCustomFields() {
    const { item } = this.props;
    const { user } = this.props;
    if(isJaguar(user)){
      if(item.custom_fields) {
        const customFields = JSON.parse(item.custom_fields).fields
        if(customFields) {
          return customFields.map(field => (
            <div className="quickview-field custom-price">
              {field.name}: <span> {field.value} </span>
            </div>
          ));
        }
      }
    }
  }

  renderDimensions() {
    const { item } = this.props;
    const dimensions = [
      {label: "H", value:item.product_height},
      {label: "W", value:item.product_width},
      {label: "L", value: item.product_length}
    ].filter(x=> x.value)
    if( dimensions.length > 0) {
      return (
        <div className="quickview-field product_dimensions">
          Dimensions: <span> {dimensions.map(d => `${d.value}"${d.label}`).join(" x ")} </span>
        </div>
      )
    }
  }

  handleTagAddition (tag) {
    const tags = [].concat(this.state.itemTags, tag)
    this.setState({ itemTags: tags })
    this.updateTags(tags);
  }

  handleTagDelete (e,i) {
    e.stopPropagation();
    if(confirm("Are you sure you want to delete this tag?")){
      const tags = this.state.itemTags.filter((item,index) => index !== i)
      this.setState({ itemTags: tags })
      this.updateTags(tags);
    }
  }

  loadTags() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/item_tags/common_tags?count=100`, {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({commonTags: json.tags || []});
        } else {
          alert(json.error);
        }
      });
  }

  updateTags(tags) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { item } = this.props;
    let values = {item: {}}
    values["item"]["tags"] = tags.map(x =>
      x.name
    )
    fetch(`/warehouse/items/${item.id}/`, {
        method: 'PATCH',
        redirect: "manual",
        body: JSON.stringify(values),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({item_tags: json.item.item_tags.map(tag => {return {id: tag.id, name: tag.tag}})})
        } else {
          alert(json.error);
        }
      });
  }


  renderTags() {
    const { itemTags } = this.state;
    const { user, event, item } = this.props;
    if(!event && !item.renter_id) {
      const tagsRendered = itemTags.map((tag, index) =>
        <div className="tag-container" >
          {tag.name}
          {isJaguar(user) ?
          <div className="tag-delete-button" onClick={(e)=>this.handleTagDelete(e,index)}>
            &#x2715;
          </div> : ""}
        </div>
      );
      if(isJaguar(user) || itemTags.length > 0) {
        return (
          <React.Fragment>
            <div className="item-full-view-tags">
              <b> Tags </b>
              <br />
              {tagsRendered}
              {isJaguar(user) ? (
                <ReactTags
                  allowDragDrop={false}
                  tags={itemTags}
                  minQueryLength={0}
                  autofocus={false}
                  suggestions={this.state.commonTags.map((tag,i) => {return {id: -1000+i, name: tag}})}
                  handleAddition={this.handleTagAddition}
                  handleDelete={this.handleTagDelete}
                  delimiters={[188,13]} //Comma Keycode, enter keycode
                  allowNew={true}
                />
                )
                : ""
              }
            </div>
          </React.Fragment>
        )
      }
    }
  }

  renderItemHolds() {
    const {item_hold, user, item, events, event, variant} = this.props;
    const { locked } = this.state;
    if(isJaguar(user) || canEditEvent(event,user)){
      if(item_hold){
          return (
            <React.Fragment>
              <ItemHoldForm item_hold={item_hold} user={user} item={item} events={events} lockOverride={locked} />
              <hr />
            </React.Fragment>
          )
        }else {
          return (
            <React.Fragment>
              <ItemHoldForm imageComponentReference={this.imageComponentReference} user={user} item={ item } events={events}/>
              <hr />
            </React.Fragment>
          )
        }
    }
  }

  renderTimeStamp() {
    const { item_hold } = this.props;
    if( item_hold ) {
      return (
        <React.Fragment>
          <div className="item-detail-last-updated">
            <br />
            Last Updated&nbsp;
            {item_hold.user ?
              (
                <span>
                  By:&nbsp;
                  <b>
                    {item_hold.user.first_name} {item_hold.user.last_name},
                  </b>
                </span>
              ) : ""
            }
            <b> {moment(item_hold.updated_at).format('MMMM D, YYYY, h:mm A z')}</b>
          </div>
        </React.Fragment>
      )
    }
  }

  getEscapeUrl(){
    const { item, event, category, user } = this.props;
    if(event){
      return `/events/${event.slug}`
    }else if(item.renter_id){
      return isJaguar(user) ? `/inventory/${item.renter_id}`: `/inventory`
    }
    return category ? `/category/${category.group_name}/${category.name}` : '/category/Other'
  }

  goBack(){
    history.back();
  }

  renderEscapeButtons() {
    const { event, category } = this.props;
    return (
      <div className="row escape-buttons">
        <div className="col-xs-6">
          <a href={this.getEscapeUrl()} >
            {`< Back`}
          </a>
        </div>
        <div className="col-xs-6 text-right">
          <a class="x" href={this.getEscapeUrl()}>
            x
          </a>
        </div>
      </div>
    )
  }

  renderItemsHeld() {
    const { item_hold, item, user } = this.props;
    if(item_hold) {
      const reserved = item_hold.total_count;
      const shipped = item_hold.status === "shipped" || item_hold.status === "received" ? reserved : 0;
      return (
        <React.Fragment>
          <div className="reserved-count"> Reserved: <b> {reserved} </b></div>
          <div className="packed-count">Shipped: <b> {shipped} </b> </div>
        </React.Fragment>
      )
    }
    else {
      return (
        (
          <React.Fragment>
            {item.renter_id ? "In Storage:" : "Own:"} <b> {item.owned_including_variants} </b>
          </React.Fragment>
        )
      );
    }
  }

  renderAdminMenu() {
    const { user, item, variant, item_hold, event } = this.props;
    if( isSuperadmin(user) ) {
      if(variant) {
        return (
          <div className="item-show-admin-menu">
            <h2> Admin Options</h2>
            <hr />
            <a href={`/warehouse/items/${item.id}/edit/`}>Edit {item.name}</a>
            <a href={`/warehouse/items/${variant.id}/edit/`}>Edit {variant.name} (variant)</a>
            {item_hold ? (<a href={`/warehouse/events/${event.slug}?hold_id=${item_hold.id}`}> Edit Item Hold </a>):''}
          </div>
        );
      }
      return (
        <div className="item-show-admin-menu">
          <h2> Admin Options</h2>
          <hr />
          <a href={`/warehouse/items/${item.id}/edit/`}>Edit Item</a>
          {item_hold ? (<a href={`/warehouse/events/${event.slug}?hold_id=${item_hold.id}`}> Edit Item Hold </a>):''}
        </div>
      )
    }
  }

  renderHoldBigButtons() {
    const {item_hold,event, admin_users,item,variant,user} = this.props;
    const { locked } = this.state;
    if(isJaguar(user)){
      if(item_hold){
        return (
          <React.Fragment>
            <HoldMessageModal headerTitle={`${item.name}${variant ? " - " + variant.name : ""}`} item_hold={item_hold} event={event} admin_users={admin_users} button={(<img className="item-show-page-big-button" src="/images/items/email_icon.png" />)} />
            {'    '}
            {locked ?
              (<a onClick={this.unlockHold} href="#"><img  className="item-show-page-big-button" src="/images/items/locked_icon.png" /></a>)
              :
              (<a href="#" onClick={this.lockHold}><img className="item-show-page-big-button"src="/images/items/unlocked_icon.png" /></a>)
            }
          </React.Fragment>
        )
      }else if(item) {
        return (
          <React.Fragment>
            <HoldMessageModal  headerTitle={`${item.name}${variant ? " - " + variant.name : ""}`} item={item} admin_users={admin_users} button={(<img className="item-show-page-big-button" src="/images/items/email_icon.png" />)} />
          </React.Fragment>
        )
      }
    }
  }

  lockHold(){
    const { item_hold, event } = this.props;
    if(item_hold) {
      if(item_hold.has_error){
        alert('Item can not be locked because it is missing information.');
        return;
      }
      if(confirm(`Are you sure you want to lock this item in? This cannot be undone.`)) {
        const cookies = new Cookies();
        const token = cookies.get("X-CSRF-Token");
        fetch(`/events/${event.slug}/item_holds/${item_hold.id}/lock`, {
          method: 'POST',
          redirect: "manual",
          body: "",
          headers: {
            "X-CSRF-Token": token,
            "Content-Type": 'application/json'
          }
        })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            location.reload();
          } else {
            alert(json.error);
          }
        });
      }
    }
  }

  unlockHold(){
    const { item_hold, event, user } = this.props;
    if(item_hold && isJaguar(user)) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/events/${event.slug}/item_holds/${item_hold.id}/unlock`, {
        method: 'POST',
        redirect: "manual",
        body: "",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            locked: false
          })
        } else {
          alert(json.error);
        }
      });
    }
  }

  renderClientMessaging(){
    const { user, item } = this.props;
    if(!isJaguar(user)){
      return (
        <React.Fragment>
          <ItemMessageComponent onCancel={this.goBack} user={user} item={item}/>
          <hr/>
        </React.Fragment>
      )
    }
  }

  render() {
    const { item, category, categories, events, user, event, item_hold, variant} = this.props;
    return (
      <PublicCatalogLayout
        {...this.props}
        subHeader={
          event ?
          (
            <EventSubheader {...this.props} style={{paddingTop: "20px"}}/>
          )
          :
          null
        }
        currentPage={
          `group-${item.category && item.category.group_name ? item.category.group_name : "other"}`
        }
        classNames={{body: 'white-background'}}
      >
        <div className="item-detail-show">
          {this.renderEscapeButtons()}
          <div className="row">
            <div className="col-xs-12 col-sm-5">
              <ItemImageComponent ref={this.imageComponentReference} item={variant || item} item_hold={item_hold}/>
              {this.renderAdminMenu()}
            </div>
            <div className="col-xs-12 col-sm-7">
              <div className="row">
                <div className="col-xs-12 col-sm-8">
                  <h1>{item.name}</h1>
                  {variant ? (<h2>{variant.name}</h2>) : ""}
                  <div className="quickview-field inventory">
                    {this.renderItemsHeld()}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 text-right item-message-buttons">
                  {this.renderHoldBigButtons()}
                </div>
                <div className="col-xs-12">
                  <hr />
                  {this.renderItemDetails()}
                  <hr/>
                  {this.renderItemHolds()}
                  {this.renderTimeStamp()}
                  {this.renderClientMessaging()}
                  {this.renderTags()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PublicCatalogLayout>
    );
  }
}

ItemShowPage.propTypes = {
  item: PropTypes.object.isRequired,
  user: PropTypes.object.optional
};

export default ItemShowPage;
