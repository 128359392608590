export const holdKey = [
  'reserved',
  'packed',
  'shipped',
  'received'
]

export function isHoldPacked(hold) {
  if(hold && hold.status){
    const packedIndex = holdKey.indexOf('packed');
    return holdKey.indexOf(hold.status) >= packedIndex
  }else {
    throw "No Hold or Incorrect Serialization of Hold for isPacked"
    return false;
  }
}

export function isHoldShipped(hold) {
  if(hold && hold.status){
    const packedIndex = holdKey.indexOf('shipped');
    return holdKey.indexOf(hold.status) >= packedIndex
  }else {
    throw "No Hold or Incorrect Serialization of Hold for isHoldShipped"
    return false;
  }
}

export function isHoldRecieved(hold) {
  if(hold && hold.status){
    const packedIndex = holdKey.indexOf('received');
    return holdKey.indexOf(hold.status) >= packedIndex
  }else {
    throw "No Hold or Incorrect Serialization of Hold for isHoldReceived"
    return false;
  }
}
