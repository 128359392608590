import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import KitCardQuickViewModal from "./KitCardQuickViewModal"

class KitCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: ""
    }
  }

  getItemCount() {
    const {items} = this.props.kit
    let total = 0;
    items.forEach((item) => {
      total += item.item_count
    })
    return total
  }

  render() {
    const { kit } = this.props;
    return (
      <div className="item-card">
        <div className="kit-card-flag-container"><div className="kit-card-flag dark-green">{"Kit " + `(${this.getItemCount()} items)`}</div></div>
        <div
          className="item-card-image"
          style={{backgroundImage: `url(${kit.images && kit.images.length > 0 ? kit.images[0].image : ""})`}}
        >
        <KitCardQuickViewModal {...this.props}/>
        </div>
        <div className="item-card-description">
          <a href={`/kits/${kit.id}`}>
            <div className="name">
              {kit.name}
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default KitCard;
