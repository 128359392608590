import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import WarehouseCategoriesModal from "./WarehouseCategoriesModal"
import Cookies from "universal-cookie";
import WarehouseGroupEditModal from "./WarehouseGroupEditModal"
class WarehouseCategoriesDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: props.categories
    }
    this.addCategory = this.addCategory.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
  }

  renderRowHeader(category, index) {
    const { categories } = this.state;
    if(index == 0 || categories[index - 1].group_name != category.group_name) {
      return (
        <tr className="group-heading">
          <td colSpan="3">
            {category.group_name || "Misc"}
            {category.group_name ? (<WarehouseGroupEditModal updateGroup={this.updateGroup} group={category.group_name}/>) :""}
          </td>
        </tr>
      )
    }
  }

  updateGroup(categories) { 
    categories.forEach(category => this.updateCategory(category));
  }

  addCategory(category) {
    const { categories } = this.state;
    categories.push(category)
    this.setState({
      categories: categories
    })
  }

  updateCategory(category) {
    this.setState((state, props) => {
      const categories = state.categories;
      console.log(category,categories);
      const index = categories.findIndex(category2 => category2.id === category.id);
      categories[index] = category;

      state.categories = categories;
      return state;
    })
  }


  renderCategoryTable() {
    let { categories } = this.state;

    categories = categories.sort((a,b) => {
      if(!a.group_name && !b.groupname) { return 0 }
      if(a.group_name > b.group_name) { return 1; }
      if(a.group_name < b.group_name || !a.group_name || !b.group_name) { return -1; }
      return 0;
    });

    const rows = categories.reverse().map((category,index) => 
      <React.Fragment>
        {this.renderRowHeader(category,index)}
        <tr key={`${category.id}`}>
          <td> {category.name}</td>
          <td> </td>
          <td>
            <button className="btn btn-danger pull-right" onClick={() => this.deleteCategory(category)}> Delete </button>
            <WarehouseCategoriesModal updateCategory={this.updateCategory} category={category} groups={this.getAllGroups()}/>
          </td>
        </tr>
      </React.Fragment>
    );
    return (
      <table className="warehouse-basic-table">
        <tbody>
            {rows}
        </tbody>
      </table>
    )
  }

  deleteCategory(category) {
    if(confirm("Are you sure? All items in this category will be moved to Misc.")){
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");

      fetch(`/warehouse/categories/${category.id}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            categories: this.state.categories.filter(category2 => category2.id != category.id)
          });
        } else {
          alert(json.error);
        }
      });      
    }
  }

  getAllGroups() {
    const { categories } = this.state;
    return categories.map(category => category.group_name)
                     .filter((value, index, self) => self.indexOf(value) === index );
  }

  render() {
    return (
      <WarehouseLayout currentPage="dashboard">
        <div className="warehouse-card">
          <div className="row">
            <div className="col-xs-12">
              <h1> Categories </h1>
              <WarehouseCategoriesModal addCategory={this.addCategory} groups={this.getAllGroups()}/>
              <br /><br />
              {this.renderCategoryTable()}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseCategoriesDashboard.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default WarehouseCategoriesDashboard;
